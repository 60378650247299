import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import styled from "@emotion/styled"
import Heading from "@ui/Heading/Heading"
import Text from "@ui/Text/Text"

const Container = styled.section`
  position: relative;
  overflow: hidden;
`

const BackgroundImage = styled(GatsbyImage)`
  height: 100% !important;
`

const ParallaxBackground = styled.div<{ scale: number }>`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  height: ${(props) => `${Math.max(100, 100 * props.scale)}%`};
  will-change: transform;
`

export const ImgTitle = styled(Heading)`
  text-shadow: 0px 1px 2px rgb(0 0 0 / 40%), 0px 3px 6px rgb(0 0 0 / 40%);
`
ImgTitle.defaultProps = {
  size: "4xl",
  fontFamily: "secondary",
  color: "white",
  textAlign: "center",
}

export const ImgCopyright = styled(Text)`
  color: #fff;
  position: absolute;
  bottom: 2rem;
  right: 0;
  margin: 0 2rem;
  font-weight: 500;
  text-shadow: 0px 2px 2px rgb(0 0 0 / 40%), 0px 3px 6px rgb(0 0 0 / 20%);

  &:before {
    content: "Picture: ";
  }
`

export interface ParallaxProps {
  scaleFactor?: number
  backgroundProps: GatsbyImageProps
  children?: React.ReactNode
}

const Parallax = ({
  scaleFactor = 1.5,
  backgroundProps,
  children,
  ...props
}: ParallaxProps) => {
  const frame = useRef(0)
  const bg = useRef<HTMLDivElement | null>(null)
  const [viewportHeight, setViewportHeight] = useState<number>()
  const [ref, inView, entry] = useInView()
  const entryTarget = entry ? entry.target : null

  useEffect(() => {
    setViewportHeight(document.documentElement.clientHeight)
  }, [])

  useEffect(() => {
    const handler = () => {
      cancelAnimationFrame(frame.current)

      if (!inView || !entryTarget || !viewportHeight) {
        return
      }

      frame.current = requestAnimationFrame(() => {
        const imageBottom = entryTarget.getBoundingClientRect().bottom
        const imageHeight = entryTarget.getBoundingClientRect().height

        const maxPercent = (1 - imageHeight / (imageHeight * scaleFactor)) * 100

        const offset =
          imageBottom * (maxPercent / (viewportHeight + imageHeight))

        const yOffsetPercent = (
          -1 * Math.min(maxPercent, Math.max(offset, 0))
        ).toFixed(4)

        if (bg.current) {
          bg.current.style.transform = `translate3d(0, ${yOffsetPercent}%, 0)`
        }
      })
    }

    if (inView) {
      window.addEventListener("scroll", handler, {
        capture: false,
        passive: true,
      })
    }

    return () => {
      cancelAnimationFrame(frame.current)
      window.removeEventListener("scroll", handler)
    }
  }, [inView, entryTarget, scaleFactor, viewportHeight])

  return (
    <Container ref={ref} {...props}>
      <ParallaxBackground scale={scaleFactor} ref={bg}>
        <BackgroundImage {...backgroundProps} />
      </ParallaxBackground>
      {children}
    </Container>
  )
}

export default Parallax
