import styled from "@emotion/styled"
import { rem } from "polished"

import illustrationSvg from "../images/placeholder_illustration.svg"

export const DividerIllustration = styled.img<{
  loading?: "eager"
}>`
  display: block;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: ${rem("128px")};
`

DividerIllustration.defaultProps = {
  src: illustrationSvg,
}
